import {
	PROJECTS_LOADED,
	PROJECT_LOADED,
	PROJECT_FAILED,
	PROJECT_LOADING,
	PARTICIPANTS_LOADED,
} from '../utils/consts';

const initialState = {
	projects: [],
	project: null,
	loading: true,
	participants: null,
};

export default function (state = initialState, action) {
	const { type, payload } = action;
	switch (type) {
		case PROJECT_LOADING:
			return {
				...state,
				project: null,
				loading: true,
			};
		case PROJECTS_LOADED:
			return {
				...state,
				loading: false,
				projects: payload,
			};
		case PROJECT_LOADED:
			return {
				...state,
				loading: false,
				project: payload,
			};
		case PROJECT_FAILED:
			return {
				...state,
				...payload,
				loading: false,
			};
		case PARTICIPANTS_LOADED:
			return {
				...state,
				participants: payload,
				loading: false,
			};
		default:
			return state;
	}
}
