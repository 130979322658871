import axios from 'axios';
import {
	PROJECTS_LOADED,
	PROJECT_LOADED,
	PROJECT_FAILED,
	PROJECT_LOADING,
	PARTICIPANTS_LOADED,
} from '../utils/consts';
import { setAlert } from './alert';

// TODO: Set some alerts using the alert action generator
export const getProjects = () => async dispatch => {
	try {
		dispatch({
			type: PROJECT_LOADING,
		});
		axios
			.get('/api/project/getAllForAdmin', {
				headers: {
					'content-type': 'application/json',
				},
			})
			.then(res => {
				dispatch({
					type: PROJECTS_LOADED,
					payload: res.data,
				});
			});
	} catch (err) {
		console.log(err.message);
		dispatch({
			type: PROJECT_FAILED,
		});
	}
};

export const getProject = id => async dispatch => {
	try {
		dispatch({
			type: PROJECT_LOADING,
		});
		axios
			.get(`/api/project/getDetails/${id}`, {
				headers: {
					'content-type': 'application/json',
				},
			})
			.then(res => {
				dispatch({
					type: PROJECT_LOADED,
					payload: res.data,
				});
			});
	} catch (err) {
		console.log(err.message);
		dispatch({
			type: PROJECT_FAILED,
		});
	}
};

export const updateProject = projectData => async dispatch => {
	if (!projectData) {
		setAlert('Please Fill at least one!', 'danger');
		return;
	}
	try {
		await axios.post(
			'/api/project/updateAdmin',
			{ ...projectData },
			{
				headers: {
					'Content-Type': 'application/json',
				},
			}
		);
		dispatch(getProject(projectData.id));
	} catch (err) {
		console.log(err);
		dispatch(setAlert('Some issues in update', 'danger'));
		dispatch({
			type: PROJECT_FAILED,
		});
	}
};

export const randomizeHighlights = (id, section) => async dispatch => {
	try {
		if (section)
			await axios.get(`/api/project/randomizeHighlights/${id}/${section}`);
		else await axios.get(`/api/project/randomizeHighlights/${id}`);
		dispatch(getProject(id));
	} catch (err) {
		console.log(err);
		dispatch(setAlert('Issues in update', 'danger'));
		dispatch({
			type: PROJECT_FAILED,
		});
	}
};

export const startRecording = projectId => async dispatch => {
	try {
		await axios.get(`/api/project/start/${projectId}`);
	} catch (err) {
		console.log(err);
		dispatch(setAlert('Some issues in starting', 'danger'));
		dispatch({
			type: PROJECT_FAILED,
		});
	}
};

export const sendReminder = projectId => async dispatch => {
	try {
		await axios.get(`/api/project/mailtopax/${projectId}`);
	} catch (err) {
		console.log(err);
		dispatch(setAlert('Some issues in sending Mails', 'danger'));
		dispatch({
			type: PROJECT_FAILED,
		});
	}
};

export const sendReminderToRejected = projectId => async dispatch => {
	try {
		await axios.get(`/api/project/mailtorejected/${projectId}`);
	} catch (err) {
		console.log(err);
		dispatch(setAlert('Some issues in sending Mails', 'danger'));
		dispatch({
			type: PROJECT_FAILED,
		});
	}
};

export const getAllPax = projectId => async dispatch => {
	try {
		console.log('TRING');
		const res = await axios.get(`/api/recording/getallpax/${projectId}`);
		dispatch({
			type: PARTICIPANTS_LOADED,
			payload: res.data,
		});
	} catch (err) {
		console.log(err);
		dispatch(setAlert('Some issues in getting pax', 'danger'));
		dispatch({
			type: PROJECT_FAILED,
		});
	}
};

export const handleRejectRecording =
	(projectId, sectionIndex, recordingIndex, message) => async dispatch => {
		if (!message || message.length === 0) {
			dispatch(setAlert('Please enter content for Mail!', 'danger'));
			return;
		}
		try {
			await axios.post(
				`/api/recording/handleReject/${projectId}`,
				{ sectionIndex, recordingIndex, message },
				{
					headers: {
						'Content-Type': 'application/json',
					},
				}
			);
			dispatch(getProject(projectId));
		} catch (err) {
			console.log(err);
			dispatch(
				setAlert('Some issues in rejecting participant, check s3', 'danger')
			);
			dispatch({
				type: PROJECT_FAILED,
			});
		}
	};
