import {
	SONG_LOADING,
	SONG_LOADED,
	SONGS_LOADED,
	SONG_FAILED,
} from '../utils/consts';

const initialState = {
	songs: [],
	song: null,
	loading: true,
};

export default function (state = initialState, action) {
	const { type, payload } = action;
	switch (type) {
		case SONG_LOADING:
			return {
				...state,
				song: null,
				loading: true,
			};
		case SONGS_LOADED:
			return {
				...state,
				loading: false,
				songs: payload,
			};
		case SONG_LOADED:
			return {
				...state,
				loading: false,
				song: payload,
			};
		case SONG_FAILED:
			return {
				...state,
				...payload,
				loading: false,
			};
		default:
			return state;
	}
}
