import { combineReducers } from 'redux';
import alert from './alert';
import register from './register';
import project from './project';
import trialSong from './trialSong';

export default combineReducers({
	alert,
	register,
	project,
	trialSong,
});
