import React from "react";

const NotAdmin = () => (
    <div className="row no-gutters">
        <p className="ver-text">
          You're not admin.<br></br>
          Please use right Login.<br></br>
        </p>
    </div>
);
export default NotAdmin;
